import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';

const Timer: FC = () => {
  useEffect(() => {
    window.location.href = 'https://install.page/makana343';
  }, []);

  return (
    <Box sx={{ color: '#1b4ed8', textAlign: 'center' }}>
      <h1 className="App-logo-text">
        Espera, estas siendo redireccionado a <a href="https://install.page/makana343">instalar la App</a>
      </h1>
    </Box>
  );
};

export default Timer;
